<template>
  <div>
    <b-button-group class="mb-1">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        to="/admin/product-setting/0"
        >عربي</b-button
      >
      <b-button
        to="/admin/product-setting/1"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
    <ValidationObserver ref="observer">
      <b-form
        slot-scope="{ validate }"
        @submit.prevent="validate().then(suc => {if(suc) onSubmitAgentDto()})"
      >
        <b-card no-body>
          <b-card-body>
            <h4>الجملة</h4>
            <BTextInputWithValidation
              rules="required"
              v-model="productsPageDto.title"
              name="عنوان الصفحة"
              placeholder="عنوان الصفحة"
            />
            <h4>شرح عن المنتجات</h4>
            <div class="editor-container">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="productsPageDto.description"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
          </b-card-body>
          <b-card-footer class="py-1">
            <b-button variant="success" @click="setProductsSettingPage(productsPageDto)">تم</b-button>
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import { ValidationObserver } from "vee-validate";
  import { mapGetters, mapActions } from "vuex";
  import { quillEditor } from "vue-quill-editor";
  import hljs from 'highlight.js'
  import debounce from 'lodash/debounce'
  export default {
    components: {
      BTextInputWithValidation,
      ValidationObserver,
      quillEditor,
    },
    props: {
      lang: String
    },
    data: () => ({
      editorOption: {
         modules: {
              toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
          syntax: {
              highlight: text => hljs.highlightAuto(text).value
          }
        },
        placeholder: "ادخل شرح عن المنتجات",
      }
    }),
    computed: {
      ...mapGetters(["productsPageDto"]),
      editor() {
        return this.$refs.myTextEditor.quill
      }
    },
    created() {
      this.getProductsSettingPage(!+this.lang ? 1 : 2);
      this.$store.dispatch('app/setLang', !+this.lang ? 1 : 2)
    },
    methods: {
      ...mapActions(["getProductsSettingPage", "setProductsSettingPage"]),
      onEditorChange: debounce(function(value) {
        this.productsPageDto.description = value.html
      }, 466),
    },
    watch: {
      lang(lang) {
        this.$store.dispatch('app/setLang', !+lang ? 1 : 2)
        this.getProductsSettingPage(!+lang ? 1 : 2);
      }
    }
  };
</script>

<style lang="scss" scoped>
.editor-container {
  height: 270px;
  .editor {
    height: 200px;
  }
}
</style>